<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="12 6@sm" align="middle">
					<Heading size="1">Generate More Traffic in your Website</Heading>
					<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut fringilla, nisl id rhoncus dapibus, purus lorem pulvinar turpis. </Paragraph>
					<Button label="Get Started" color="primary" />
				</GridContainer>
				<GridContainer size="12 6@sm">
					<Images width="100%" image="theme-ed/tech2.png" />
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 6@sm 4@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="link" background="primary" color="light" size="large" />
								<Heading size="4" align="center">Link Building</Heading>
								<Paragraph align="center" micro>Morbi porttitor rutrum diam sit amet vehicula. Maecenas et massa lectus.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 6@sm 4@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="support" background="secondary" color="light" size="large" />
								<Heading size="4" align="center">Support</Heading>
								<Paragraph align="center" micro>Morbi porttitor rutrum diam sit amet vehicula. Maecenas et massa lectus.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="12 4@md">
					<Card>
						<CardSection>
							<Stack direction="column" space="2">
								<Icon icon="send" background="tertiary" color="light" size="large" />
								<Heading size="4" align="center">One Page SEO</Heading>
								<Paragraph align="center" micro>Morbi porttitor rutrum diam sit amet vehicula. Maecenas et massa lectus.</Paragraph>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="7" align="middle">
					<Images width="100%" image="theme-ed/tech1.png" />
				</GridContainer>
				<GridContainer size="5" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">We Offer a Full Range of Digital Marketing Services!</Heading>
						<Paragraph micro>Maecenas aliquam rhoncus nunc, vitae lobortis lectus pulvinar nec. Duis quis accumsan lorem, non dignissim neque. Morbi mattis condimentum urna, vehicula dapibus tellus egestas et. Sed ac augue dui. Cras interdum ante id lorem porttitor, vitae placerat magna fermentum.</Paragraph>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Global Reach</Heading>
									<Paragraph micro>Up to 100%</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Big Experience</Heading>
									<Paragraph micro>Expert Worker</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Convenience</Heading>
									<Paragraph micro>To reach your target</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="medium" />
								<Stack direction="column" align="left">
									<Heading size="5">Team Strength</Heading>
									<Paragraph micro>Clients Satisfaction</Paragraph>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="primary">
			<Stack direction="column" space="3">
				<Heading size="5" color="primary" uppercase>What we do</Heading>
				<Heading size="2" align="center">Let's Check Our Scientific<br/> Digital Marketing solutions</Heading>
				<Slider time="10000" height="450px" type="nav">
					<SliderContainer>
						<Grid>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="share" background="primary" color="light" size="large" />
											<Heading size="4">Social Media Marketing</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="search" background="secondary" color="light" size="large" />
											<Heading size="4">Keyword Research</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="sort" background="tertiary" color="light" size="large" />
											<Heading size="4">Content Marketing</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
						</Grid>
					</SliderContainer>
					<SliderContainer>
						<Grid>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="sort" background="primary" color="light" size="large" />
											<Heading size="4">PPC Advertising</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="share" background="secondary" color="light" size="large" />
											<Heading size="4">Competitor Research</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Card>
									<CardSection>
										<Stack direction="column" space="2" align="left">
											<Icon icon="share" background="tertiary" color="light" size="large" />
											<Heading size="4">Social Media Marketing</Heading>
											<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna blandit tellus.</Paragraph>
											<Button label="Read More" color="primary" type="outline" />
										</Stack>
									</CardSection>
								</Card>
							</GridContainer>
						</Grid>
					</SliderContainer>
				</Slider>
			</Stack>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="12 6@md">
					<Images width="100%" image="theme-ed/tech3.png" />
				</GridContainer>
				<GridContainer size="12 6@md" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">We love what we Do<br/>And Helping others Succeed</Heading>
						<Stack direction="row" space="3" align="top">
							<Icon icon="search" color="primary" size="large" />
							<Stack direction="column" align="left">
								<Heading size="5">01. Research</Heading>
								<Paragraph micro>Vestibulum et maximus nisl. Phasellus eros sem, molestie ut dapibus ac, sollicitudin eu mi.</Paragraph>
							</Stack>
						</Stack>
						<Stack direction="row" space="3" align="top">
							<Icon icon="insights" color="primary" size="large" />
							<Stack direction="column" align="left">
								<Heading size="5">02. Data Collection</Heading>
								<Paragraph micro>Vestibulum et maximus nisl. Phasellus eros sem, molestie ut dapibus ac, sollicitudin eu mi.</Paragraph>
							</Stack>
						</Stack>
						<Stack direction="row" space="3" align="top">
							<Icon icon="track_changes" color="primary" size="large" />
							<Stack direction="column" align="left">
								<Heading size="5">03. Targeting</Heading>
								<Paragraph micro>Vestibulum et maximus nisl. Phasellus eros sem, molestie ut dapibus ac, sollicitudin eu mi.</Paragraph>
							</Stack>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Stack direction="column" space="3">
				<Heading size="5" color="primary" uppercase>Our Pricing</Heading>
				<Heading size="2" align="center">Take a look at our Pricing and<br/>Select Your Choice</Heading>
				<Grid>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Trial Version</Heading>
									<Heading size="2">Free</Heading>
									<Icon icon="send" background="light" color="dark" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Button label="Try for Free" color="primary" type="outline" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Regular</Heading>
									<Stack justify="center">
										<Heading size="2" margin="none">$29</Heading>
										<Paragraph margin="none" micro>/Mo</Paragraph>
									</Stack>
									<Icon icon="star" background="light" color="primary" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Button label="Get Started" color="primary" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Extended</Heading>
									<Stack justify="center">
										<Heading size="2" margin="none">$59</Heading>
										<Paragraph margin="none" micro>/Mo</Paragraph>
									</Stack>
									<Icon icon="work" background="light" color="dark" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Button label="Get Started" color="primary" type="outline" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
				</Grid>
			</Stack>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="6" align="middle">
					<Images width="100%" image="theme-ed/tech4.png" />
				</GridContainer>
				<GridContainer size="6">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">What people say <br/> About our Services</Heading>
						<Slider time="10000" height="250px" type="dots" collapse>
							<SliderContainer>
								<Paragraph micro>Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst.</Paragraph>
								<Heading size="5" color="primary">Droila Abhi</Heading>
								<Paragraph micro>Marketing Manager</Paragraph>
							</SliderContainer>
							<SliderContainer>
								<Paragraph micro>Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst.</Paragraph>
								<Heading size="5" color="primary">Droila Abhi</Heading>
								<Paragraph micro>Marketing Manager</Paragraph>
							</SliderContainer>
						</Slider>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section color="primary">
			<Stack direction="column" space="3">
				<Heading size="5" color="primary" uppercase>Latest News</Heading>
				<Heading size="2" align="center">Stay Updated with Dallas Digital<br/>from latest &amp; popular News</Heading>
					<Grid>
						<GridContainer size="12 6@sm 4@md">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" image="theme-ed/blog1.jpg" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="3" align="left">
										<Heading size="6" color="primary">Performance, SEO</Heading>
										<Stack space="2">
											<Icon icon="date_range" size="small" color="primary" />
											<Heading size="6" uppercase>1 January 2022</Heading>
											<Heading size="6" uppercase>By Admin</Heading>
										</Stack>
										<Heading size="4">Melancholy particular devonshire alter</Heading>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="12 6@sm 4@md">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" image="theme-ed/blog2.jpg" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="3" align="left">
										<Heading size="6" color="primary">Affiliate, Process</Heading>
										<Stack space="2">
											<Icon icon="date_range" size="small" color="primary" />
											<Heading size="6" uppercase>2 January 2022</Heading>
											<Heading size="6" uppercase>By Admin</Heading>
										</Stack>
										<Heading size="4">Future plan &amp; strategy for construction</Heading>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="12 6@sm 4@md">
							<Card>
								<CardSection collapse>
									<Images height="250px" width="100%" image="theme-ed/blog3.jpg" />
								</CardSection>
								<CardSection>
									<Stack direction="column" space="3" align="left">
										<Heading size="6" color="primary">Affiliate, Analysis</Heading>
										<Stack space="2">
											<Icon icon="date_range" size="small" color="primary" />
											<Heading size="6" uppercase>5 January 2022</Heading>
											<Heading size="6" uppercase>By Admin</Heading>
										</Stack>
										<Heading size="4">Participate in staff meetingness manage</Heading>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
					</Grid>
			</Stack>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/edison/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
